<template>
  <div class="wrapper index">
    <div class="box-height" style="padding-bottom: 5px">
      <el-row
        :gutter="20"
        style="display: flex; margin-left: -5px; margin-right: -5px"
      >
        <el-col :span="9" class="box">
          <p class="tit">消息中心</p>
          <div v-if="massageList.length > 0" @click="showDrawer($event)">
            <vue-seamless-scroll
              :data="massageList.length > 5 ? massageList : nomessage"
              :class-option="defaultOption"
              class="scroll"
            >
              <div
                class="item"
                v-for="(i, index) in massageList"
                :key="index"
                :data-obj="JSON.stringify(i)"
              >
                <p class="ipopover">
                  <el-popover
                    :content="i.content"
                    placement="bottom-start"
                    :data-type="i.type"
                    width="500"
                    trigger="hover"
                    :open-delay="1000"
                    :data-relativeId="i.relativeId"
                    :data-id="i.id"
                  >
                    <span
                      class="textoverSpan"
                      slot="reference"
                      :data-obj="JSON.stringify(i)"
                      >{{ i.content }}</span
                    >
                  </el-popover>
                  <span
                    style="
                      color: #999;
                      font-size: 12px;
                      width: 100px;
                      float: right;
                      display: inline-block;
                    "
                    :data-obj="i"
                    >{{ i.createdTime }}</span
                  >
                </p>
              </div>
            </vue-seamless-scroll>
          </div>
          <div v-else>
            <div class="nodata">
              <img src="../assets/indexNoData.png" alt="" />
              <p>暂无未读消息</p>
            </div>
          </div>
        </el-col>
        <el-col :span="15" class="box" style="margin-left: 10px">
          <div class="topright">
            <div class="tobox">
              <div class="tit">
                <p style="display: flex; align-items: center">
                  <i
                    class="iconfont icon icon-sy_jrsx"
                    style="margin-right: 5px; font-size: 16px; color: #898989"
                  ></i>
                  <span>{{
                    ListData.length > 0 ? "今日事项" : "当日无待办事项"
                  }}</span>
                </p>
                <el-button
                  size="mini"
                  icon="el-icon-plus"
                  @click="addCalendar"
                  style="
                    padding: 4px;
                    margin-left: 10px;
                    width: 22px;
                    height: 22px;
                  "
                ></el-button>
              </div>
              <div
                v-if="ListData.length > 0"
                class="scrollbar"
                style="
                  height: 258px;
                  overflow-y: auto;
                  margin-left: 0 15px 0 20px;
                "
              >
                <div
                  class="tolist"
                  v-for="(item, index) in ListData"
                  :key="index"
                >
                  <p :class="item.done ? 'disble' : ''">
                    <el-checkbox
                      style="margin-left: 0px"
                      v-model="item.done"
                      @change="check(index)"
                    >
                    </el-checkbox>
                    <el-tag size="mini" class="totag">{{ index + 1 }}</el-tag>
                    {{ item.scheduleHourTime }}
                    【{{ item.scheduleType }}】
                    <span
                      v-if="
                        item.moduleType == '人才' || item.moduleType == '客户'
                      "
                      :class="item.done ? '' : 'tabHref'"
                      @click="goDetail(item)"
                      >{{ item.targetName }}</span
                    >

                    <router-link
                      v-if="item.moduleType == '项目'"
                      class="tabHref"
                      target="_blank"
                      style="text-decoration: none"
                      :to="{
                        path: '/project/details',
                        query: { id: item.targetId },
                      }"
                      >{{ item.targetName }}
                    </router-link>
                    <span
                      v-if="
                        item.moduleType == '项目' &&
                        item.scheduleType !== '客户电话' &&
                        item.scheduleType !== '客户拜访' &&
                        item.scheduleType !== '客户邮件'
                      "
                      :class="item.done ? '' : 'tabHref'"
                      @click="goDetail(item)"
                    >
                      {{ item.relatePersonName }}</span
                    >
                    <span
                      v-if="
                        item.moduleType == '客户' ||
                        item.scheduleType == '客户电话' ||
                        item.scheduleType == '客户拜访' ||
                        item.scheduleType == '客户邮件'
                      "
                    >
                      {{ item.relatePersonName }}</span
                    >
                  </p>
                </div>
              </div>
              <div v-else>
                <div class="nodata">
                  <img src="../assets/indexNoData.png" alt="" />
                  <el-button
                    round
                    size="mini"
                    icon="el-icon-plus"
                    @click="addCalendar"
                    >新增日程</el-button
                  >
                </div>
              </div>
            </div>
            <div>
              <calendar
                ref="calendar"
                :events="calendar.events"
                :lunar="calendar.lunar"
                :value="calendar.value"
                :begin="calendar.begin"
                :end="calendar.end"
                :weeks="calendar.weeks"
                :months="calendar.months"
                @select="select"
                @selectMonth="selectMonth"
                @selectYear="selectYear"
              ></calendar>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row
        :gutter="20"
        class="chartsContent"
        style="margin-left: -5px; margin-right: -5px"
      >
        <el-col
          :span="19"
          class="charts"
          style="border-right: 1px #ececec solid"
        >
          <div
            class="line"
            :style="{ height: '250px', width: '100%', margin: '0 auto' }"
            ref="Performance"
            id="Performance"
          ></div>
        </el-col>
        <el-col :span="5" class="totalAnnual">
          <p class="t_tit">年度业绩总计（{{ year }}）</p>
          <ul>
            <li>
              <i class="iconfont el-icon-s-help"></i> Offer业绩
              <span>{{ achievementSum.offerAchievement }}</span>
            </li>
            <li>
              <i class="iconfont el-icon-s-help"></i> 到岗业绩
              <span>{{ achievementSum.entryAchievement }}</span>
            </li>
            <li>
              <i class="iconfont el-icon-s-help"></i> 开票业绩
              <span>{{ achievementSum.invoiceAchievement }}</span>
            </li>
            <li>
              <i class="iconfont el-icon-s-help"></i> 回款业绩
              <span>{{ achievementSum.accountAchievement }}</span>
            </li>
            <el-divider></el-divider>
            <li style="color: #e60012">
              <i class="iconfont el-icon-s-help"></i> 逾期回款额
              <span class="tabHref" @click="showAchievementDetail">{{
                achievementSum.overDueAccountAchievement
              }}</span>
            </li>
          </ul>
        </el-col>
      </el-row>
      <el-row
        :gutter="20"
        class="chartsContent"
        style="margin-left: -5px; margin-right: -5px"
      >
        <el-col :span="24" class="charts">
          <div
            class="line"
            :style="{ height: '250px', width: '100%', margin: '0 auto' }"
            ref="kpi"
            id="kpi"
          ></div>
        </el-col>
      </el-row>
    </div>
    <!-- 抽屉弹窗 -->
    <el-drawer
      :visible.sync="dialogVisible"
      custom-class="drawerClass"
      direction="ttb"
      :modal="false"
      append-to-body
      :before-close="handleClose"
      destroy-on-close
    >
      <div slot="title" class="drawerTitle">
        <i
          v-if="titName == '合同详情'"
          class="drawIcon iconfont icon-xm-xq"
        ></i>
        <i
          v-if="titName == '财务详情'"
          class="drawIcon iconfont icon-pl_cjcw"
        ></i>
        <i v-if="titName == '客户'" class="drawIcon iconfont icon-kh_xq"></i>
        <i v-if="titName == '人才'" class="drawIcon iconfont icon-kh_xq"></i>
        {{ titName }}
      </div>
      <div class="drawerCon">
        <contractInfo
          v-if="titName == '合同详情'"
          :contractId="relativeId"
          :activeName="'创建'"
          @handle="dramClose"
        ></contractInfo>
        <financeRecord
          :financeTaskId="relativeId"
          v-if="titName == '财务详情'"
          :handleFinance="$route.path == '/financeEnquiries' ? false : true"
          :isFinance="true"
        ></financeRecord>
        <customerInfo
          v-if="titName == '客户'"
          @toEdit="toEditComInfo"
          :customId="customId"
          :activeToInfo="activedToInfo"
        ></customerInfo>
        <resumeDetails
          v-if="titName == '人才'"
          :resumeDetailId="resumeId"
          :hover="hoverInfo"
          :repeatResume="repeatResume"
        ></resumeDetails>
        <sysMessage
          v-if="titName == '系统公告' || titName == '系统更新'"
          ref="sysMessage"
          :dataDetail="dataDetail"
        ></sysMessage>
        <noComtractApporval
          ref="noComtractApporval"
          v-if="titName == '无合同开票申请审批'"
          :id="relativeId"
          @close="handleClose"
        ></noComtractApporval>
        <aothers
          ref="aothers"
          :row="kpiRow"
          v-if="titName == '消息详情'"
          @close="handleClose"
        ></aothers>
      </div>
    </el-drawer>
    <!-- 新增日程 -->
    <el-dialog
      :visible.sync="calendarVisible"
      :modal-append-to-body="false"
      append-to-body
      title="新增日程"
      :before-close="caleCencel"
      :close-on-click-modal="false"
      width="600px"
      destroy-on-close
    >
      <addorEditCalender
        ref="calenderFrom"
        v-if="calendarVisible"
        :formdata="calendardata"
        :Ryear="R_year"
        @close="caleCencel"
        @getlist="loadTask"
      ></addorEditCalender>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="caleCencel('calendardata')"
          >取 消</el-button
        >
        <el-button
          size="mini"
          :loading="saveloading"
          type="primary"
          @click="saveCalendar()"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 审核 -->
    <el-drawer
      :visible.sync="isfinancialTask"
      append-to-body
      :modal="false"
      custom-class="drawerClass"
      :close-on-click-modal="false"
      direction="ttb"
      :before-close="handleClose1"
    >
      <div slot="title" class="drawerTitle">
        <div><i class="drawIcon iconfont icon-pl_xb"></i>{{ tit }}</div>
      </div>
      <!-- 发喜报-->
      <div class="drawerCon">
        <happyNews
          v-if="isfinancialTask"
          ref="saveHappyNews"
          inpath="happyNewsApproval"
          :projectResumeId="projectResumeId"
          @getisApproval="getisApproval"
          @changeSaveLoading="changeSaveLoading"
          @loadList="getloadList"
        ></happyNews>
        <div class="drawerCon_footer fixedFoot" v-if="isapproval">
          <el-button
            type="default"
            plain
            size="mini"
            @click="rejectedHappyNews()"
            >驳回</el-button
          >
          <el-button
            type="primary"
            size="mini"
            :loading="saveloading"
            @click="saveInfos"
            style="margin-right: 15px"
            >确认并提交</el-button
          >
        </div>
      </div>
    </el-drawer>
    <!-- 逾期回款业绩详情 -->
    <el-drawer
      :visible.sync="showAchievementDetailVisible"
      append-to-body
      :modal="false"
      custom-class="drawerClass"
      :close-on-click-modal="false"
      direction="ttb"
      :before-close="chievementDetailClose"
    >
      <div slot="title" class="drawerTitle">
        <div>逾期回款业绩详情</div>
      </div>
      <div class="drawerCon">
        <el-row :gutter="24">
          <el-pagination
            :hide-on-single-page="pagination.total > 10 ? false : true"
            v-if="pagination.total > 0"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pagination.current"
            :page-sizes="[10, 20, 50, 100]"
            :pager-count="5"
            :page-size="pagination.size"
            layout="total,prev, pager, next,sizes"
            :total="pagination.total"
          ></el-pagination>
          <el-col :span="24">
            <el-table
              :header-cell-style="{
                'background-color': '#f5f7fa',
              }"
              :data="achievementDetail"
              class="tabBorder"
              v-loading="listLoading"
            >
              <el-table-column
                label="项目名称"
                prop="projectName"
                min-width="220"
              >
                <template #default="scope">
                  <router-link
                    class="tabHref"
                    target="_blank"
                    :to="{
                      path: '/project/details',
                      query: { id: scope.row.projectId },
                    }"
                    >{{ scope.row.projectName }}
                  </router-link>
                </template>
              </el-table-column>
              <el-table-column prop="resumeName" label="候选人" min-width="120">
                <template #default="scope">
                  <p
                    class="tabHref"
                    @click="
                      goDetail({
                        moduleType: '人才',
                        targetId: scope.row.resumeID,
                      })
                    "
                  >
                    {{ scope.row.resumeName }}
                  </p>
                </template>
              </el-table-column>
              <el-table-column
                prop="invoiceDate"
                label="开票时间"
                min-width="120"
              ></el-table-column>
              <el-table-column
                prop="invoiceAmount"
                label="开票金额"
                min-width="120"
              ></el-table-column>
              <el-table-column
                prop="accountMoney"
                label="到账金额"
                min-width="120"
              ></el-table-column>
              <el-table-column
                prop="accountStatus"
                label="到账状态"
                min-width="120"
              ></el-table-column>
              <el-table-column
                prop="conventionToAccountDate"
                label="约定到账时间"
                min-width="120"
              ></el-table-column>
              <el-table-column
                prop="overDueDays"
                label="已超期"
                min-width="120"
              >
                <template #default="scope">
                  <p>{{ scope.row.overDueDays }}天</p>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
        <el-pagination
          :hide-on-single-page="pagination.total > 10 ? false : true"
          v-if="pagination.total > 0"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pagination.current"
          :page-sizes="[10, 20, 50, 100]"
          :pager-count="5"
          :page-size="pagination.size"
          layout="total,prev, pager, next,sizes"
          :total="pagination.total"
        ></el-pagination>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { mapGetters } from "vuex";
import {
  indexMessage,
  readMessage,
  schedulePage,
  getScheduleStatusById, //标记状态
  personalAchievement, //个人业绩分析
  personalKpi, //个人KPI分析
  getSchedulePersonal, //当前用户所有日程
  getAnnouncementDetail, //获取系统公告、更新详情
  offerGoodnewsApprovalReject, //驳回
  personalAchievementSum, //个人年度业绩
  personalAchievementsDetail, //逾期回款详情
} from "../api/api";
import {
  statisticsMessageReadDuration, //系统更新消息阅读时长
  statisticsMessageRead, //点击统计查看消息
} from "../api/buryingPoint";
import vueSeamlessScroll from "vue-seamless-scroll";
import contractInfo from "../components/contract/contractInfo";
import financeRecord from "../components/finance/record";
import calendar from "../components/rili/rili";
import customerInfo from "../components/customer/customerInfo";
import resumeDetails from "../components/resumeDetail";
import addorEditCalender from "../components/calendar/from";
import sysMessage from "../components/sysMessage.vue";
import happyNews from "../components/happyNews";
import noComtractApporval from "../components/tools/noComtractApporval";
import aothers from "../components/message/others";
var d = new Date();
var year = d.getFullYear();
var month = d.getMonth() + 1;
var day = d.getDate();
if (month >= 1 && month <= 9) {
  month = "0" + month;
}
if (day >= 1 && day <= 9) {
  day = "0" + day;
}
const date = year + "-" + month + "-" + day;
export default {
  components: {
    vueSeamlessScroll,
    contractInfo,
    financeRecord,
    calendar,
    customerInfo,
    resumeDetails,
    addorEditCalender,
    sysMessage,
    happyNews,
    noComtractApporval,
    aothers,
  },
  name: "index",

  data() {
    return {
      massageList: [],
      nomessage: [],
      dialogVisible: false,
      titName: "",
      relativeId: null,
      id: null,
      calendar: {
        value: [], //默认日期
        lunar: true, //显示农历
        zero: true,
        weeks: ["日", "一", "二", "三", "四", "五", "六"],
        months: [
          "1月",
          "2月",
          "3月",
          "4月",
          "5月",
          "6月",
          "7月",
          "8月",
          "9月",
          "10月",
          "11月",
          "12月",
        ],
        events: {},
        timestamp: Date.now(),
      },
      ListData: [],
      R_year: date,
      checkList: [],
      activedToInfo: "1",
      customId: null,
      repeatResume: { type: "列表", valId: "" },
      resumeId: null,
      hoverInfo: { matchAll: true, majorSearch: false, hover: true },
      calendarVisible: false,
      calendardata: {
        moduleType: "人才",
        comment: "",
        joinUserIds: [],
        scheduleType: "联系候选人",
        scheduleTime: null,
        targetId: null,
        relatePersonId: null,
      },
      personalAchievementList: {
        monthName: [], //月份名称
        target: [], //业绩目标
        offerAchievement: [], //offer业绩
        entryAchievement: [], //到岗业绩
        invoiceAchievement: [], //开票业绩
        accountAchievement: [], //总回款
        completeRate: [], //达成率
        offerDifference: [], //Offer业绩差额
        entryDifference: [], //到岗业绩差额
        invoiceDifference: [], //开票业绩差额
        accountDifference: [], //总回款差额
      },
      year: new Date().getFullYear(),
      kpiList: {
        monthName: [], //月份名称
        resumeComment: [], //人才备注
        internInterview: [], //内部面试
        recommend: [], //简历推荐
        customerInterviewFirst: [], //客户面试_初
        offer: [], //offer
        projectBd: [], //项目BD
        faceToFace: [], //F2F面试
        customerVisit: [], //客户拜访
      },
      dataDetail: {},
      isfinancialTask: false,
      saveloading: false,
      projectResumeId: null,
      isapproval: false,
      tit: "喜报审核",
      achievementSum: {},
      showAchievementDetailVisible: false,
      achievementDetail: [],
      listLoading: false,
      pagination: {
        current: 1,
        size: 50,
        total: 0,
      },
      kpiRow: {},
      meassgePointData: {
        operatedTime: "",
        entry: "首页",
        first: true,
        id: "",
        leaveTime: "",
        sendTime: "",
        title: "",
        type: "",
      },
      clickMsgData: {
        clickTime: "",
        entry: "首页",
        first: true,
        messageId: "",
        type: "",
      },
    };
  },
  // 监听属性 类似于data概念
  computed: {
    defaultOption() {
      return {
        step: 0.2, // 数值越大速度滚动越快
        limitMoveNum: 1, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
    ...mapGetters({
      messageList: "getMessageList",
    }),
  },
  watch: {
    dialogVisible(newValue) {
      if (newValue) {
        this.noScroll(); //禁止滚动
      } else {
        this.canScroll();
      }
    },
  },
  created() {
    this.getMassage();
    this.loadTask(this.R_year);
    //个人业绩分析
    this.getPersonalAchievement();
    //个人年度业绩
    this.getAchievementSum();
    //个人kpi
    this.getPersonalKpi();
    //当前用户所有日程
    this.getSchedulePersonal();
  },
  methods: {
    getloadList() {
      this.isfinancialTask = false;
      this.getMassage();
    },
    //获取消息中心列表
    getMassage() {
      indexMessage().then((res) => {
        if (res.success) {
          this.massageList = res.result;
        }
      });
    },
    //关闭弹窗
    handleClose(done) {
      // done();
      this.cancel();
      if (this.titName == "系统更新") {
        this.meassgePointData.leaveTime = new Date().Format(
          "yyyy-MM-dd hh:mm:ss"
        );
        statisticsMessageReadDuration(this.meassgePointData).then((res) => {});
      }
    },
    dramClose() {
      this.dialogVisible = false;
      this.getMassage();
    },
    //取消
    cancel() {
      this.dialogVisible = false;
      this.calendarVisible = false;
      this.caleCencel();
      this.getMassage();
    },
    //弹出抽屉
    showDrawer(e) {
      let data = JSON.parse(e.target.dataset.obj);
      let type = data.type;
      let relativeId = data.relativeId;
      let id = data.id;
      this.relativeId = relativeId;
      this.id = id;
      readMessage({ id: id }).then((res) => {
        if (res.success) {
          this.$store.dispatch(
            "updateMessageList",
            this.messageList.map((item) => {
              if (item.label == e.type) {
                if (item.count > 0) {
                  return {
                    ...item,
                    count: item.count - 1,
                  };
                }
                return item;
              }
              return item;
            })
          );
          this.$store.dispatch("decrement");
        }
      });
      if (type == "合同消息") {
        this.titName = "合同详情";
        this.dialogVisible = true;
      } else if (type == "系统公告") {
        getAnnouncementDetail({ id: relativeId }).then((res) => {
          if (res.success) {
            this.dataDetail = res.result;
            this.titName = res.result.type;

            if (
              res.result.type == "系统更新" ||
              res.result.type == "系统公告"
            ) {
              //埋点
              this.clickMsgData.first = !data.isRead;
              this.meassgePointData.type = res.result.type;
              this.meassgePointData.operatedTime = new Date().Format(
                "yyyy-MM-dd hh:mm:ss"
              );
              this.meassgePointData.sendTime = res.result.createdTime;
              this.meassgePointData.title = res.result.title;
              this.meassgePointData.id = res.result.id;
            }
          }
        });
        this.dialogVisible = true;
      } else if (type == "喜报消息") {
        this.projectResumeId = relativeId;
        this.isfinancialTask = true;
      } else if (type == "无合同开票消息") {
        //  this.projectResumeId = relativeId;
        this.titName = "无合同开票申请审批";
        this.dialogVisible = true;
      } else if (
        type == "人才备注无效" ||
        type == "内部面试无效" ||
        type == "简历推荐无效" ||
        type == "客户面试无效" ||
        type == "Offer无效" ||
        type == "项目BD无效" ||
        type == "F2F面试无效"
      ) {
        //  this.projectResumeId = relativeId;
        this.titName = "消息详情";
        this.kpiRow = {
          type: type,
          relativeId: relativeId,
        };
        this.dialogVisible = true;
      } else {
        this.titName = "财务详情";
        if (relativeId) {
          this.dialogVisible = true;
        } else {
          this.$router.push({ path: "/report/Pool" });
        }
      }
      this.clickMsgData.clickTime = new Date().Format("yyyy-MM-dd hh:mm:ss");
      this.clickMsgData.type = type;
      this.clickMsgData.messageId = data.id;
      statisticsMessageRead(this.clickMsgData).then();
    },
    select(value) {
      if (value[1] >= 1 && value[1] <= 9) {
        value[1] = "0" + value[1];
      }
      if (value[2] >= 1 && value[2] <= 9) {
        value[2] = "0" + value[2];
      }
      this.R_year = value[0] + "-" + value[1] + "-" + value[2];
      this.loadTask(this.R_year);
    },
    selectMonth(month, year) {},
    selectYear(year) {},
    //日程列表
    loadTask(date) {
      const obj = {
        page: {
          current: 1,
          size: 10000,
          orders: [{ asc: true, column: "scheduleTime" }],
        },
        scheduleStatus: null,
        scheduleStartTime: date,
        scheduleEndTime: date,
      };
      schedulePage(obj).then((res) => {
        if (res.success) {
          for (var i = 0; i < res.result.records.length; i++) {
            if (res.result.records[i].scheduleStatus == "未完成") {
              res.result.records[i].done = false;
            } else {
              res.result.records[i].done = true;
            }
          }
          this.ListData = res.result.records;
        }
      });
    },
    //tolist勾选
    check(e) {
      if (this.ListData[e].done) {
        this.ListData[e].done = false;
      } else {
        this.ListData[e].done = true;
      }
      const params = new FormData();
      params.append("id", this.ListData[e].id);
      params.append(
        "scheduleStatus",
        this.ListData[e].done ? "未完成" : "已完成"
      );
      let time = this.ListData[e].scheduleTime.split(" ", 1);
      this.R_year = time[0];
      getScheduleStatusById(params).then((res) => {
        if (res.success) {
          this.$message.success(res.message);
          this.loadTask(this.R_year);
        }
      });
    },
    //显示详情（客户、人才）
    goDetail(item) {
      if (item.moduleType == "人才") {
        this.titName = "人才";
        this.resumeId = item.targetId;
      } else if (item.moduleType == "客户") {
        this.titName = "客户";
        this.customId = item.targetId;
      } else if (item.moduleType == "项目") {
        if (
          item.scheduleType == "客户电话" ||
          item.scheduleType == "客户拜访" ||
          item.scheduleType == "客户邮件"
        ) {
        } else {
          this.titName = "人才";
          this.resumeId = item.relatePersonId;
        }
      }
      this.dialogVisible = true;
    },
    toEditComInfo(id) {
      this.drawerTitle = "编辑客户";
      this.customId = id;
    },
    // 新增日程
    addCalendar() {
      this.calendarVisible = true;
    },
    //取消
    caleCencel() {
      this.calendarVisible = false;
      this.calendardata.moduleType = "人才";
      this.calendardata.comment = "";
      this.calendardata.joinUserIds = [];
      this.calendardata.scheduleType = "联系候选人";
      this.calendardata.scheduleTime = "";
      this.calendardata.targetId = null;
      this.calendardata.relatePersonId = null;
      this.calendardata.id = null;
      this.targetId1 = 0;
    },
    //保存新增日程
    saveCalendar() {
      this.$refs.calenderFrom.saveInfo();
    },
    //个人业绩分析
    getPersonalAchievement() {
      let list = {
        monthName: [], //月份名称
        target: [], //业绩目标
        offerAchievement: [], //offer业绩
        entryAchievement: [], //到岗业绩
        invoiceAchievement: [], //开票业绩
        accountAchievement: [], //总回款
        completeRate: [], //达成率
        offerDifference: [], //Offer业绩差额
        entryDifference: [], //到岗业绩差额
        invoiceDifference: [], //开票业绩差额
        accountDifference: [], //总回款差额
      };
      personalAchievement().then((res) => {
        if (res.success) {
          res.result.forEach((element) => {
            list.monthName.push(element.monthName); //月份名称
            list.target.push(element.target); //业绩目标
            list.offerAchievement.push(element.offerAchievement); //offer业绩
            list.entryAchievement.push(element.entryAchievement); //到岗业绩
            list.invoiceAchievement.push(element.invoiceAchievement); //开票业绩
            list.accountAchievement.push(element.accountAchievement); //总回款
            list.completeRate.push(element.completeRate); //达成率
            list.offerDifference.push(element.offerDifference); //Offer业绩差额
            list.entryDifference.push(element.entryDifference); //到岗业绩差额
            list.invoiceDifference.push(element.invoiceDifference); //开票业绩差额
            list.accountDifference.push(element.accountDifference); //总回款差额
          });
          this.personalAchievementList = list;
          this.lineBarChart();
        }
      });
    },
    //个人业绩分析图表
    lineBarChart() {
      let chartDom = document.getElementById("Performance");
      let myChart = echarts.init(chartDom);
      let option;
      let colors = [
        "#8da7f2",
        "#b589eb",
        "#92d671",
        "#f2c561",
        "#e86666",
        "#b7bee2",
      ];
      option = {
        color: colors,
        title: {
          text: "个人业绩分析（" + this.year + "）",
          textStyle: {
            fontSize: 16,
            color: "#333",
          },
          left: "0",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
          },
          textStyle: {
            color: "#fff",
          },
          backgroundColor: "rgba(51,51,51,0.95)",
          borderWidth: "0",
          formatter: function (params) {
            let str = params[0].name;
            params.forEach((item, index) => {
              if (
                item.seriesName !== "差额_Offer业绩" &&
                item.seriesName !== "差额_到岗业绩" &&
                item.seriesName !== "差额_回款业绩" &&
                item.seriesName !== "差额_总回款业绩"
              ) {
                str =
                  str +
                  "<br/>" +
                  item.marker +
                  item.seriesName +
                  " : " +
                  item.value;
              }
            });
            return str;
          },
        },
        grid: {
          right: "5%",
          top: "25%",
          left: "5%",
          bottom: "25",
        },
        legend: {
          data: [
            "业绩目标",
            "Offer业绩",
            "到岗业绩",
            "开票业绩",
            "回款业绩",
            // "差额",
            "达成率",
          ],
          top: "0%",
          itemWidth: 20,
          itemHeight: 12,
        },
        xAxis: [
          {
            type: "category",
            axisTick: {
              interval: 0,
            },
            axisLabel: {
              show: true,
              rotate: "0",
              fontSize: 12,
              color: "#0F1109", //X轴文字颜色
            },
            data: this.personalAchievementList.monthName,
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "金额（元）",
            min: 0,
            position: "left",
            axisLabel: {
              formatter: "{value}",
            },
          },
          {
            type: "value",
            name: "达成率(%)",
            min: 0,
            position: "right",
            offset: 0,
            axisLabel: {
              formatter: "{value} %",
            },
          },
        ],
        series: [
          {
            name: "业绩目标",
            type: "bar",
            stack: "one",
            barWidth: "10",
            barIds: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
            data: this.personalAchievementList.target,
            itemStyle: {
              borderRadius: [2, 2, 0, 0],
            },
          },
          {
            name: "Offer业绩",
            type: "bar",
            stack: "two",
            barWidth: "10",
            data: this.personalAchievementList.offerAchievement,
            itemStyle: {
              borderRadius: [2, 2, 0, 0],
            },
          },
          {
            name: "差额_Offer业绩",
            type: "bar",
            barWidth: "10",
            stack: "two",
            data: this.personalAchievementList.offerDifference,
            itemStyle: {
              borderRadius: [2, 2, 0, 0],
              color: "rgba(237,167,167,0.3)",
            },
          },
          {
            name: "到岗业绩",
            type: "bar",
            stack: "three",
            barWidth: "10",
            data: this.personalAchievementList.entryAchievement,
            itemStyle: {
              borderRadius: [2, 2, 0, 0],
            },
          },
          {
            name: "差额_到岗业绩",
            type: "bar",
            stack: "three",
            barWidth: "10",
            data: this.personalAchievementList.entryDifference,
            itemStyle: {
              borderRadius: [2, 2, 0, 0],
              color: "rgba(237,167,167,0.3)",
            },
          },
          {
            name: "开票业绩",
            type: "bar",
            stack: "four",
            barWidth: "10",
            data: this.personalAchievementList.invoiceAchievement,
            itemStyle: {
              borderRadius: [2, 2, 0, 0],
            },
          },
          {
            name: "差额_开票业绩",
            type: "bar",
            stack: "four",
            barWidth: "10",
            data: this.personalAchievementList.invoiceDifference,
            itemStyle: {
              borderRadius: [2, 2, 0, 0],
              color: "rgba(237,167,167,0.3)",
            },
          },
          {
            name: "回款业绩",
            type: "bar",
            barWidth: "10",
            stack: "five",
            data: this.personalAchievementList.accountAchievement,
            itemStyle: {
              borderRadius: [2, 2, 0, 0],
            },
          },
          {
            name: "差额_总回款业绩",
            type: "bar",
            barWidth: "10",
            stack: "five",
            data: this.personalAchievementList.accountDifference,
            itemStyle: {
              borderRadius: [2, 2, 0, 0],
              color: "rgba(237,167,167,0.3)",
            },
          },
          {
            name: "达成率",
            type: "line",
            yAxisIndex: 1,
            data: this.personalAchievementList.completeRate,
            symbol: "none",
            symbolSize: "5",
          },
        ],
      };
      window.addEventListener("resize", () => {
        myChart.resize();
      });
      option && myChart.setOption(option);
    },
    //个人KPI分析
    getPersonalKpi() {
      let list = {
        monthName: [], //月份名称
        resumeComment: [], //人才备注
        internInterview: [], //内部面试
        recommend: [], //简历推荐
        customerInterviewFirst: [], //客户面试_初
        offer: [], //offer
        projectBd: [], //项目BD
        faceToFace: [], //F2F面试
        customerVisit: [], //客户拜访
      };
      personalKpi().then((res) => {
        if (res.success) {
          res.result.forEach((element) => {
            list.monthName.push(element.monthName); //月份名称
            list.resumeComment.push(element.resumeComment); //人才备注
            list.internInterview.push(element.internInterview); //内部面试
            list.recommend.push(element.recommend); //简历推荐
            list.customerInterviewFirst.push(element.customerInterviewFirst); //客户面试_初
            list.offer.push(element.offer); //offer
            list.projectBd.push(element.projectBd); //项目BD
            list.faceToFace.push(element.faceToFace); //F2F面试
            list.customerVisit.push(element.customerVisit); //客户拜访
          });
          this.kpiList = list;
          this.lineChart();
        }
      });
    },
    //个人KPI分析图表
    lineChart() {
      let chartDom = document.getElementById("kpi");
      let myChart = echarts.init(chartDom);
      let option;
      let colors = [
        "#8fa9f2",
        "#b589eb",
        "#94d774",
        "#f2c561",
        "#e86666",
        "#eb68d7",
        "#52c98d",
        "#5a6cd5",
      ];
      option = {
        color: colors,
        title: {
          text: "个人KPI分析（" + this.year + "）",
          textStyle: {
            fontSize: 16,
            color: "#333",
          },
          left: "0",
        },
        tooltip: {
          trigger: "axis",
          textStyle: {
            color: "#fff",
          },
          backgroundColor: "rgba(51,51,51,0.95)",
          borderWidth: "0",
        },
        legend: {
          data: [
            "人才备注",
            "内部面试",
            "简历推荐",
            "客户面试_初",
            "offer",
            "项目BD",
            "F2F面试",
            "客户拜访",
          ],
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "0",
          containLabel: true,
        },
        toolbox: {
          top: -8,
          feature: {
            saveAsImage: {
              show: true,
              title: "保存",
              type: "png",
              lang: ["点击保存"],
            },
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.kpiList.monthName,
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            name: "人才备注",
            type: "line",
            data: this.kpiList.resumeComment,
            symbolSize: "5",
            symbol: "none",
          },
          {
            name: "内部面试",
            type: "line",
            data: this.kpiList.internInterview,
            symbolSize: "5",
            symbol: "none",
          },
          {
            name: "简历推荐",
            type: "line",
            data: this.kpiList.recommend,
            symbolSize: "5",
            symbol: "none",
          },
          {
            name: "客户面试_初",
            type: "line",
            data: this.kpiList.customerInterviewFirst,
            symbolSize: "5",
            symbol: "none",
          },
          {
            name: "offer",
            type: "line",
            data: this.kpiList.offer,
            symbolSize: "5",
            symbol: "none",
          },
          {
            name: "项目BD",
            type: "line",
            data: this.kpiList.projectBd,
            symbolSize: "5",
            symbol: "none",
          },
          {
            name: "F2F面试",
            type: "line",
            data: this.kpiList.faceToFace,
            symbolSize: "5",
            symbol: "none",
          },
          {
            name: "客户拜访",
            type: "line",
            data: this.kpiList.customerVisit,
            symbolSize: "5",
            symbol: "none",
          },
        ],
      };
      window.addEventListener("resize", () => {
        myChart.resize();
      });
      option && myChart.setOption(option);
    },
    //当前用户所有日程
    getSchedulePersonal() {
      getSchedulePersonal().then((res) => {
        if (res.success) {
          this.calendar.events = res.result;
        }
      });
    },
    //喜报弹窗关闭
    handleClose1() {
      this.isfinancialTask = false;
      this.cancel();
    },
    chievementDetailClose() {
      this.showAchievementDetailVisible = false;
      this.getAchievementSum();
    },
    //驳回
    rejectedHappyNews() {
      offerGoodnewsApprovalReject(this.projectResumeId).then((res) => {
        if (res.success) {
          this.$message.success("驳回成功");
          this.isfinancialTask = false;
          this.getMassage();
        }
      });
    },
    changeSaveLoading(val) {
      this.saveloading = val;
    },
    saveInfos() {
      this.$refs.saveHappyNews
        .saveHappyNewsApproval()
        .then(() => {
          this.saveloading = false;
        })
        .catch(() => {
          this.saveloading = false;
        });
    },
    //判断是否审核
    getisApproval(data) {
      if (data == "待审批") {
        this.isapproval = true;
        this.tit = "喜报审批";
      } else {
        this.isapproval = false;
        this.tit = "喜报";
      }
    },
    //个人年度业绩
    getAchievementSum() {
      personalAchievementSum().then((res) => {
        if (res.success) {
          this.achievementSum = res.result;
        }
      });
    },
    showAchievementDetail() {
      this.showAchievementDetailVisible = true;
      this.getPersonalAchievementsDetail();
    },
    getPersonalAchievementsDetail() {
      this.listLoading = true;
      personalAchievementsDetail(this.pagination).then((res) => {
        if (res.success) {
          this.achievementDetail = res.result.records;
          this.pagination.total = res.result.total;
          this.listLoading = false;
        }
      });
    },
    handleSizeChange(val) {
      //pageSize 改变时会触发
      this.pagination.size = val;
      this.pagination.current = 1;
      this.getPersonalAchievementsDetail();
    },
    handleCurrentChange(val) {
      //currentPage 改变时会触发
      this.pagination.current = val;
      this.getPersonalAchievementsDetail();
    },
  },
};
</script>

<style lang="scss" scoped>
.index {
  .box {
    background: #fff;
    border: 1px #e5e5e5 solid;
    padding: 15px 15px 20px !important;
    border-radius: 3px;
    &:hover {
      -webkit-box-shadow: 0 3px 8px 1px #e0e0e0;
      box-shadow: 0 3px 8px 1px #e0e0e0;
    }
    .tit {
      font-size: 16px;
      font-weight: bold;
      margin: 0px 0 10px;
      display: flex;
      align-content: center;
      color: #333;
    }
    .scroll {
      height: 258px;
      overflow: hidden;
      .item {
        cursor: pointer;
        padding: 10px;
        color: #606266;
        font-size: 13px;
        overflow: auto;
        p {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
      .item:nth-child(odd) {
        background: #fbfbfb;
      }
    }
    .textoverSpan {
      width: 100%;
      display: inline-block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      &:hover {
        text-decoration: underline;
      }
    }
    .topright {
      display: flex;
      justify-content: space-between;
      .calendarbox {
        width: 250px;
        border: 1px #e5e5e5 solid;
        padding: 5px;
        border-radius: 5px;
      }
      .tobox {
        margin: 0 0px 10px;
        width: calc(100% - 250px);
        .tolist {
          display: flex;
          align-items: center;
          padding: 15px 0;
          border-bottom: #e5e5e5 1px dashed;
          p {
            margin-top: -2px;
          }
        }
      }
    }
  }
  .chartsContent {
    background: #fff;
    margin: 10px 0;
    border: 1px #e5e5e5 solid;
    &:hover {
      -webkit-box-shadow: 0 3px 8px 1px #e0e0e0;
      box-shadow: 0 3px 8px 1px #e0e0e0;
    }
    .charts {
      padding: 15px;
      border-radius: 3px;
    }
  }
  .totalAnnual {
    padding: 15px 10px;
    .t_tit {
      font-size: 15px;
      font-weight: bold;
      line-height: 30px;
    }
    ul {
      margin: 15px 15%;
      li {
        font-size: 15px;
        line-height: 30px;
        span {
          margin-left: 15px;
        }
      }
    }
    :deep(.el-divider--horizontal) {
      margin: 10px 0;
    }
  }
}
.disble {
  text-decoration: line-through;
  color: #999 !important;
}
.totag {
  margin: 0px 10px;
  padding: 0 2px;
  width: 14px;
  height: 14px;
  line-height: 14px;
}
.nodata {
  text-align: center;
  margin: 90px 0;
  img {
    width: 130px;
    height: 75px;
  }
  p {
    font-size: 14px;
    color: #a8a8a8;
    margin: 10px;
  }
  .el-button.is-round {
    display: block;
    margin: 5px auto;
  }
}
.ipopover {
  width: 100%;
  > span:nth-child(1) {
    width: calc(100% - 150px);
  }
}
</style>
<style>
.el-tooltip__popper.is-dark,
.el-tooltip__popper.is-light {
  max-width: 300px;
}
</style>
<style  lang="scss">
.index {
  .el-popover__reference-wrapper {
    line-height: 1;
  }
  p ~ .el-popover__reference-wrapper {
    display: inline-block;
    width: calc(100% - 150px);
    // float: left;
  }
}
.scrollbar::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 8px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
.scrollbar::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  box-shadow: inset 0 0 5px #ededed;
  background: #ededed;
}
.scrollbar::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  border-radius: 10px;
  background: #fff;
}
</style>
